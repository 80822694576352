@use 'sections/home.scss';
@use 'sections/formInformation.scss';
@use 'sections/services.scss';
@use 'sections/carousel.scss';
html {
    scroll-behavior: smooth;
    color: black;
}

body {
    > div {
        background-color: white;
    }
}