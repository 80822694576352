@charset "utf-8";

/******* Fonts Import Start **********/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
/********* Fonts Face CSS End **********/

/******* Common Element CSS Start ******/

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-blog {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1440px) {
    width: 100%;
  }
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide a {
  width: 100%;
}

.swiper-slide .item {
  display: flex;
  width: 100%;
  background-size: cover;
}
.swiper-wrapper {
  .swiper-slide .item .item-desc {
    width: 100%;
    min-height: 80px;
    padding: 14px;
    //margin: 10px;
    margin-top: auto;
    //margin: auto 0 10px 10px;
    //border-radius: 8px;
    background-color:rgba(255, 255, 255, 0.9);
  }

  @media (min-width: 1024px) {
    .swiper-wrapper .swiper-slide .item .item-desc {
      height: 550px; /* Ajusta la altura según tus necesidades en pantallas grandes */
    }
    
  }
  
}

.carousel {
  width: 100%;

  @media (min-width: 700px) {
    margin: 0 auto;
  }
}

